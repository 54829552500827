<template>
    <div class="console-config">
        <h5>彩票设置</h5>

        <div class="mt-3">
            <form @submit="onSubmit">
                <div class="row">
                    <div class="col-auto">
                        <label>获取彩票方式</label>
                        <select name="" id="" v-model="options">
                            <option value="">链接</option>
                            <option value="">定向</option>
                        </select>
                    </div>
                </div>

                <div class="row">

                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {}
            }
        },
        methods: {
            onSubmit(e) {
                e.preventDefault();
            }
        }
    }
</script>